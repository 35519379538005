import transcendence from '../assets/transcendence_home.webp'
import meteo from '../assets/meteo.png'
import rnsa from '../assets/rnsa.png'
import '../style/Projects.scss'
import useNav from './useNav';

function Projects(props) {

    const ProjectsRef = useNav("Projects");
    const rnsaOberonFr = "Site web du RNSA Oberon d'une vingtaine de pages. Il contient des éléments complexes de navigation et de pré-remplissage, d'intégration de logiciel tier, ou encore des design sophistiqué me demandant de re-développer de nombreux objets."
    const transcendenceFr = "Site web permettant aux utilisateurs de jouer au célèbre jeu Pong en ligne. Les fonctionnalités de liste d'amis, de messagerie instantanée, de personnalisation des maps, de matchmaking, offre une expérience de jeu personnalisée et interactive à nos utilisateurs."
    const meteoDuSouffleFr = "Site web one-page présentant l'application Météo du Souffle. La complexité de ce projet résidait dans le design et son intégration nativement impossible dans Wordpress."
    const rnsaOberonEn = "RNSA Oberon's website is about twenty pages long. It contains complex navigation and pre-filling elements, third-party software integration, as well as sophisticated designs that required me to redevelop numerous objects."
    const transcendenceEn = "The website allows users to play the famous game Pong online. The features of friend lists, instant messaging, map customization, matchmaking, offer a personalized and interactive gaming experience to our users."
    const meteoDuSouffleEn = "A one-page website presenting the Météo du Souffle application. The complexity of this project lay in the design and its natually impossible integration into Wordpress."


    return (
        <div ref={ProjectsRef} className='Projects' id="Projects">
            <p className='title'>{props.language ? "Some Projects" : "Divers Projets"}</p>
            <ProjectLeft img={rnsa} title="RNSA Oberon" txt={props.language ? rnsaOberonEn : rnsaOberonFr} />
            <ProjectRight img={transcendence} title="Ft_Transcendence" txt={props.language ? transcendenceEn : transcendenceFr} />
            <ProjectLeft img={meteo} title="Météo du Souffle" txt={props.language ? meteoDuSouffleEn : meteoDuSouffleFr} />
        </div>
    );
}

export default Projects

function ProjectLeft(props) {
    return (
        <div className='ProjectLeft'>
            <div className='LeftPart'>
                <div className='TitlePart'>
                    <hr />
                    <p className='ProjectTitle'>{props.title}</p>
                </div>
                <p className='ProjectTxt'>{props.txt}</p>
            </div>
            <div className='RightPart'>
                <img src={props.img} />
            </div>
        </div >
    );
}

function ProjectRight(props) {
    return (
        <div className='ProjectRight'>
            <div className='LeftPart'>
                <img src={props.img} />
            </div>
            <div className='RightPart'>
                <div className='TitlePart'>
                    <hr />
                    <p className='ProjectTitle'>{props.title}</p>
                </div>
                <p className='ProjectTxt'>{props.txt}</p>
            </div>
        </div >
    );
}