import { Image } from "@mui/icons-material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

function SkillCard(props) {
    return (
        <div className="SkillCard">
            <div className="ImgPart">
                <img src={props.logo} />
            </div>
            <p>{props.name}</p>
        </div>
    );
}

export default SkillCard