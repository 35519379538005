import '../style/global.scss'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

function Footer() {
    return (
        <div id="Footer">
            <div>
                <a href='https://github.com/cgangaro'>
                    <GitHubIcon id="github" />
                </a>
                <a href='https://fr.linkedin.com/in/camille-gangarossa-2ab929227?trk=public_profile_browsemap'>
                    <LinkedInIcon />
                </a>
            </div>
            <p>Copyright©2023 <span>Camille Gangarossa</span> ALL RIGHT RESERVED</p>
        </div>
    )
}

export default Footer