import '../style/Skills.scss'
import SkillCard from './SkillCard';
import mobile from '../assets/mobile.png'
import android_logo from '../assets/android_logo.svg'
import kotlin_logo from '../assets/kotlin_logo.svg'
import flutter_logo from '../assets/flutter_logo.png'
import react_logo from '../assets/react_logo.png'
import cpp_logo from '../assets/cpp_logo.svg'
import js_logo from '../assets/js_logo.svg'
import redux_logo from '../assets/redux_logo.svg'
import ts_logo from '../assets/ts_logo.svg'
import dart_logo from '../assets/dart_logo.svg'
import nestjs_logo from '../assets/nestjs_logo.svg'
import wordpress_logo from '../assets/wordpress_logo.svg'
import elementor_logo from '../assets/elementor_logo.svg'
import docker_logo from '../assets/docker_logo.svg'
import css_logo from '../assets/css_logo.png'
import html_logo from '../assets/html_logo.png'
import c_logo from '../assets/c_logo.svg'
import useNav from './useNav';

function Skills(props) {

    const skillsRef = useNav("Skills");

    return (
        <div id="Skills">
            <p className='title'>{props.language ? "My Skills" : "Mes compétences"}</p>
            <div className='ContentPart'>
                <div className='SkillsCardPart'>
                    <div className='SkillsRow'>
                        <SkillCard logo={flutter_logo} name="Flutter" />
                        <SkillCard logo={android_logo} name="Android" />
                        <SkillCard logo={react_logo} name="React" />
                        <SkillCard logo={nestjs_logo} name="Nestjs" />
                    </div>
                    <div ref={skillsRef} className='SkillsRow'>
                        <SkillCard logo={dart_logo} name="Dart" />
                        <SkillCard logo={kotlin_logo} name="Kotlin" />
                        <SkillCard logo={js_logo} name="JavaScript" />
                        <SkillCard logo={ts_logo} name="TypeScript" />
                    </div>
                    <div className='SkillsRow'>
                        <SkillCard logo={css_logo} name="CSS" />
                        <SkillCard logo={html_logo} name="HTML" />
                        <SkillCard logo={cpp_logo} name="C++" />
                        <SkillCard logo={redux_logo} name="Redux" />
                    </div>
                    <div className='SkillsRow'>
                        <SkillCard logo={wordpress_logo} name="Wordpress" />
                        <SkillCard logo={elementor_logo} name="Elementor" />
                        <SkillCard logo={c_logo} name="C" />
                        <SkillCard logo={docker_logo} name="Docker" />
                    </div>
                </div>
                <div className='RightPart'>
                    <img src={mobile} alt="Developer" />
                </div>
            </div>


        </div>
    );
}

export default Skills