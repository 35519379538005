import '../style/About.scss'
import think from '../assets/code_thinking.svg'
import programming from '../assets/programming.svg'
import { useRef } from 'react';
import useNav from './useNav';

function About(props) {

    const aboutRef = useNav("About");

    const fr1 = "Je suis constamment à l'affût des dernières tendances en matière de développement pour déployer des solutions modernes et performantes.\nMon expérience m'a permis de travailler sur une variété de projets, allant de sites web simples à\ndes applications mobiles complexes."
    const fr2 = "En parcourant mon portfolio, vous pourrez découvrir certains de mes projets passés ainsi que mes compétences techniques.\nN'hésitez pas à me contacter si vous avez des questions ou si vous souhaitez discuter d'un projet potentiel.\nJe serais ravi de pouvoir travailler avec vous pour donner vie à vos idées et créer des applications exceptionnelles !"
    const en1 = "By browsing my portfolio, you can discover some of my past projects as well as my technical skills. Please do not hesitate to contact me if you have any questions or if you would like to discuss a potential project. I would be delighted to work with you to bring your ideas to life and create exceptional applications!"
    const en2 = "Web and mobile developer passionate about creating innovative and intuitive applications. I am constantly on the lookout for the latest development trends to deploy modern and high-performance solutions. My experience has allowed me to work on a variety of projects, ranging from simple websites to complex mobile applications."

    return (
        <div ref={aboutRef} id="About">
            <div className='leftPart'>
                <img src={programming} alt="Developer" />
            </div>
            <div className='rightPart'>
                <p className='title'>{props.language ? "About" : "À propos"}</p>
                <div className='txtPart'>
                    <hr />
                    <div className='txt'>
                        <p>{props.language ? en1 : fr1}</p>
                        <p>{props.language ? en2 : fr2}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default About