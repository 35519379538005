import '../style/Contact.scss'
import React, { useState, useRef } from 'react';
import contact from '../assets/contact1.svg'
import emailjs from '@emailjs/browser';
import { Button } from '@mui/material';
import { useSnackbar, enqueueSnackbar } from 'notistack'
import useNav from './useNav';

const serviceId = "service_ib3t1b6";
const templateId = "template_9kn308l";
const publicKey = "30kQuwq3ek_ogkpWH";

function Contact(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const [count, setCount] = useState(0);
    const form = useRef();

    const ContactRef = useNav("Contact");

    const { enqueueSnackbar } = useSnackbar();

    const messageVerification = () => {
        let verif = true;
        if (name.length <= 3) {
            enqueueSnackbar('Your name must be at least 3 characters ', { variant: "error", autoHideDuration: 2000 })
            verif = false;
        }
        if (email.length <= 10) {
            enqueueSnackbar('Your name must be at least 10 characters ', { variant: "error", autoHideDuration: 2000 })
            verif = false;
        }
        if (text.length <= 50) {
            enqueueSnackbar('Your message must be at least 50 characters', { variant: "error", autoHideDuration: 2000 })
            verif = false;
        }
        return verif
    }

    const submit = (e) => {
        e.preventDefault();
        if (messageVerification()) {
            setText("");
            setCount(0);
            emailjs.sendForm(serviceId, templateId, form.current, publicKey)
                .then((result) => {
                    enqueueSnackbar('Message sent', { variant: "success", autoHideDuration: 2000 })
                }, (error) => {
                    enqueueSnackbar('Sorry, an error has occurred', { variant: "error", autoHideDuration: 2000 })
                });
        }
    };

    const nameFr = "Votre nom"
    const emailFr = "Votre e-mail"
    const projectFr = "Votre projet"
    const nameEn = "Your name"
    const emailEn = "Your e-mail"
    const projectEn = "Your project"

    return (
        <div ref={ContactRef} id="Contact">
            <div className='title'>
                <p>{props.language ? "Contact me" : "Me contacter"}</p>
            </div>
            <div className='contentPart'>
                <div className='leftPart'>
                    <form ref={form}>
                        <input name='name' maxLength={50} type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder={props.language ? nameEn : nameFr} />
                        <input name='email' maxLength={50} type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={props.language ? emailEn : emailFr} />
                        <div className='textArea'>
                            <textarea name='text' maxLength={2000} value={text} onChange={(e) => { setText(e.target.value); setCount(e.target.value.length) }} placeholder={props.language ? projectEn : projectFr} />
                            <p>{count}/2000</p>
                        </div>
                        <Button onClick={(e) => submit(e)}>{props.language ? "Send" : "Envoyer"}</Button>
                    </form>
                </div>
                <div className='rightPart'>
                    <img src={contact} />
                </div>
            </div>
        </div>
    );
}

export default Contact