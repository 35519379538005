import '../style/NavBar.scss'
import React, { useState, useContext, useEffect } from 'react';
import logo from '../assets/logo.png'
import { Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { NavContext } from './NavContext'
import Switch from '@mui/material/Switch';

function NavBar(props) {
    const [clicked, setClicked] = useState(false);
    const { activeLinkId } = useContext(NavContext);

    const handleClick = () => {
        setClicked(!clicked)
    }

    const handleClickHome = () => {
        props.homeRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickAbout = () => {
        props.aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickSkills = () => {
        props.skillsRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickProjects = () => {
        props.projectsRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickContact = () => {
        props.contactRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <nav className={clicked ? "show-nav" : ""} >
            <a onClick={handleClickHome} >
                <img id="logo" src={logo} alt="Logo" />
            </a>
            <div>
                <ul className={clicked ? "activeUl" : ""} >
                    <li>
                        <a onClick={handleClickAbout} className={activeLinkId == "About" ? 'active' : ''}>{props.language ? "About" : "À propos"}</a>
                    </li>
                    <li>
                        <a onClick={handleClickSkills} className={activeLinkId == "Skills" ? 'active' : ''}>{props.language ? "Skills" : "Compétences"}</a>
                    </li>
                    <li>
                        <a onClick={handleClickProjects} className={activeLinkId == "Projects" ? 'active' : ''}>{props.language ? "Projects" : "Projets"}</a>
                    </li>
                    <li>
                        <a onClick={handleClickContact} className={activeLinkId == "Contact" ? 'active' : ''}>Contact</a>
                    </li>
                </ul>
                <Button className={activeLinkId == "Contact" ? 'active' : ''} id="contactButton" onClick={handleClickContact}>
                    <MailOutlineIcon id="mailIcon" />
                </Button>
                <div id="switchDiv">
                    <p>FR</p>
                    <SwitchLanguage setLanguage={props.setLanguage} />
                    <p>EN</p>
                </div>
            </div>
            <button onClick={handleClick} className='burger'>
                <span className='burger-bar'></span>
            </button>
        </nav>

    );
}

export default NavBar

function SwitchLanguage(props) {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.setLanguage(event.target.checked);
    };

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}