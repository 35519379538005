import './App.scss';
import './style/Home.scss'
import './style/global.scss'
import Nav from './components/NavBar'
import Home from './components/Home'
import About from './components/About'
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import { useRef, useState } from 'react'
import NavBar from './components/NavBar';
import NavProvider from './components/NavContext';

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);
  const [language, setLanguage] = useState(false);

  return (
    <BrowserRouter>
      <NavProvider>
        <NavBar homeRef={homeRef} aboutRef={aboutRef} skillsRef={skillsRef} projectsRef={projectsRef} contactRef={contactRef} language={language} setLanguage={setLanguage} />
        <div ref={homeRef} className="App">
          <Home aboutRef={aboutRef} language={language} />
          <div ref={aboutRef} ></div>
          <About language={language} />
          <div ref={skillsRef} ></div>
          <Skills language={language} />
          <div ref={projectsRef} ></div>
          <Projects language={language} />
          <div ref={contactRef} ></div>
          <Contact language={language} />
          <Footer />
        </div>
      </NavProvider>
    </BrowserRouter>
  );
}

export default App;
