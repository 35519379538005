import { createContext, useState } from "react";

export const NavContext = createContext({
    activeLinkId: null,
    setActiveLinkId: () => undefined
})


function NavProvider({ children }) {
    const [activeLinkId, setActiveLinkId] = useState(null);

    const providerValue = {
        activeLinkId,
        setActiveLinkId
    }

    return (
        <NavContext.Provider value={providerValue}>
            {children}
        </NavContext.Provider>
    )
}

export default NavProvider;