import developer_img from '../assets/developer_activity.svg'
import { Button } from '@mui/material';
import useNav from './useNav';

function Home(props) {

    const HomeRef = useNav("Home");

    const handleClickHome = () => {
        props.aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div ref={HomeRef} className='Home'>
            <div className='divTitle2'>
                <p>Hello !</p>
                <p><span>Camille</span>,</p>
                <p>{props.language ? "web & mobile developper" : "développeur web & mobile"}</p>
                <Button onClick={handleClickHome} variant="outlined">{props.language ? "Come on" : "Découvrir"}</Button>
            </div>
            <img id="developer_img" src={developer_img} alt="Developer" />
        </div>
    );
}

export default Home